import { graphql } from 'gatsby';
import { FixedObject } from 'gatsby-image';
import React from 'react';
import { Helmet } from 'react-helmet';

import { css } from '@emotion/core';

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import Pagination from '../components/Pagination';
import { PostCard } from '../components/PostCard';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
    inner,
    outer,
    PostFeed,
    Posts,
    SiteDescription,
    SiteHeader,
    SiteHeaderContent,
    SiteMain,
    SiteTitle,
    SiteHeaderStyles,
} from '../styles/shared';
import config from '../website-config';
import { PageContext } from './post';

export interface IndexProps {
    pageContext: {
        currentPage: number;
        numPages: number;
    };
    data: {
        logo: {
            childImageSharp: {
                fixed: FixedObject;
            };
        };
        header: {
            childImageSharp: {
                fixed: FixedObject;
            };
        };
        allMarkdownRemark: {
            edges: Array<{
                node: PageContext;
            }>;
        };
    };
}

const IndexPage: React.FC<IndexProps> = props => {
    return (
        <IndexLayout css={HomePosts}>
            <Helmet>
                <html lang={config.lang} />
                <title>{config.title}</title>
                <meta name="description" content={config.description} />
                {config.googleSiteVerification && (
                    <meta name="google-site-verification" content={config.googleSiteVerification} />
                )}               
            </Helmet>
            <Wrapper>
                <div
                    css={[outer, SiteHeader, SiteHeaderStyles]}
                    className="site-header-background"
                    style={{
                        backgroundImage: `url('${props.data.header.childImageSharp.fixed.src}')`,
                    }}
                >
                    <div css={inner}>
                        <SiteNav isHome />
                        <SiteHeaderContent className="site-header-conent">
                            <SiteTitle className="site-title">
                                <img
                                    style={{ maxHeight: '80px' }}
                                    src={props.data.logo.childImageSharp.fixed.src}
                                    alt={config.title}
                                />
                            </SiteTitle>
                            <SiteDescription>{config.description}</SiteDescription>
                        </SiteHeaderContent>
                    </div>
                </div>
                <main id="site-main" css={[SiteMain, outer]}>
                    <div css={[inner, Posts]}>
                        <div css={[PostFeed]}>
                            <PostCard key={props.data.allMarkdownRemark.edges[0].node.fields.slug} post={props.data.allMarkdownRemark.edges[0].node} large={true} />
                        </div>
                    </div>
                    <div style={{textAlign: "center"}}>
                        <div style={{fontSize: 40}}>
                        Vad är Arossy?
                        </div>
                        <div style={{marginTop: 30, marginBottom: 50, maxWidth: 1000, marginLeft: "auto", marginRight: "auto"}}>
                            Arossy är ett system för att hantera arbetsordning digitalt. Genom att ersätta era handskrivna listor med Arossy får ni ordning på processerna. 
                            Genom att använda Arossy Analys kan ni hitta flaskhalsar i processen och öka effektiviteten.
                        </div>
                        
                    </div>
                    <div css={[inner, Posts]}>
                        <div css={[PostFeed]}>
                            {props.data.allMarkdownRemark.edges.slice(1).map((post) => {
                                // filter out drafts in production
                                return (
                                    (post.node.frontmatter.draft !== true ||
                                        process.env.NODE_ENV !== 'production') && (
                                        <PostCard key={post.node.fields.slug} post={post.node} large={false}/>
                                    )
                                );
                            })}
                        </div>
                    </div>
                </main>
                {props.children}
                {props.pageContext.numPages > 1 && (
                    <Pagination
                        currentPage={props.pageContext.currentPage}
                        numPages={props.pageContext.numPages}
                    />
                )}
                <Footer />
            </Wrapper>
        </IndexLayout>
    );
};

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    logo: file(relativePath: { eq: "img/arossy-logo.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    header: file(relativePath: { eq: "img/blog-cover.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 2000, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { draft: { ne: true } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          timeToRead
          frontmatter {
            title
            date
            draft
            excerpt
            image {
              childImageSharp {
                fluid(maxWidth: 3720) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            author {
              id
              bio
              avatar {
                children {
                  ... on ImageSharp {
                    fluid(quality: 100, srcSetBreakpoints: [40, 80, 120]) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          excerpt
          fields {
            layout
            slug
          }
        }
      }
    }
  }
`;

const HomePosts = css`
  @media (min-width: 795px) {
    .post-card-large {
      flex: 1 1 100%;
      flex-direction: row;
      padding-bottom: 40px;
      min-height: 280px;
      border-top: 0;
    }

    .post-card-large .post-card-title {
      margin-top: 0;
      font-size: 3.2rem;
    }

    .post-card-large:not(.no-image) .post-card-header {
      margin-top: 0;
    }

    .post-card-large .post-card-image-link {
      position: relative;
      flex: 1 1 auto;
      margin-bottom: 0;
      min-height: 380px;
    }

    .post-card-large .post-card-image {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .post-card-large .post-card-content {
      flex: 0 1 361px;
      justify-content: center;
    }

    .post-card-large .post-card-title {
      margin-top: 0;
      font-size: 3.2rem;
    }

    .post-card-large .post-card-content-link {
      padding: 0 0 0 40px;
    }

    .post-card-large .post-card-meta {
      padding: 0 0 0 40px;
    }

    .post-card-large .post-card-excerpt p {
      margin-bottom: 1.5em;
      font-size: 1.8rem;
      line-height: 1.5em;
    }
  }
`;

export default IndexPage;
